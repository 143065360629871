import { Injectable } from '@angular/core';
import { Observable, of, Observer } from 'rxjs';
import { ChatMessageModel } from '../../models/chat/chat-message.model';

@Injectable({
  providedIn: 'root'
})
export class ChatService {

  constructor() {

  }

  getDiscussion(idDiscussion: number): Observable<Array<ChatMessageModel>> {
    return of(this.getFakeDiscussion());
  }

  sendMessage(id: number, message: string): Observable<ChatMessageModel> {
    return Observable.create(
      (observer: Observer<ChatMessageModel>) => {
        setTimeout(() => {
          observer.next(this.getFakeMessageAfterSend(message));
        }, 1000);
      });
  }

  generateMessageAvantEnvoie(id: number, message: string) {
    return this.getFakeMessageBeforeSend(message);
  }

  // fake data
  private getFakeDiscussion(): Array<ChatMessageModel> {
    return [{
      personne: "p",
      messageId: 1,
      userId: '210000198410281948',
      userName: 'Question',
      userAvatar: '',
      toUserId: '140000198202211138',
      time: Date.now(),
      message: "Bonjour rav, peut on utiliser une lingette chabath ?",
      status: 'success'
    },
    {
      personne: "r",
      messageId: 1,
      userId: '210000198410281948',
      userName: 'Rav abichid',
      userAvatar: '',
      toUserId: '140000198202211138',
      time: Date.now(),
      message: "ça dépend si la lingette et beaucoup mouillé",
      status: 'success'
    }]
  }

  private getFakeMessageBeforeSend(messageSend: string): ChatMessageModel {
    let message: ChatMessageModel = {
      personne: "p",
      message: messageSend,
      status: 'pending'
    }

    return message;
  }
  private getFakeMessageAfterSend(messageSend: string): ChatMessageModel {
    let message: ChatMessageModel = {
      personne: "p",
      messageId: 1,
      userId: '210000198410281948',
      userName: 'Question',
      userAvatar: '',
      toUserId: '140000198202211138',
      time: Date.now(),
      message: messageSend,
      status: 'success'
    }

    return message;
  }
}
