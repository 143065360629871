import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'accueil',
    pathMatch: 'full'
  },

  // accueil
  {
    path: 'accueil',
    loadChildren: './pages/accueil/accueil.module#AccueilPageModule'
  },

  // compte user
  {
    path: 'historique-questions',
    loadChildren: './pages/mon-compte/historique-questions/historique-questions.module#HistoriqueQuestionsPageModule'
  },
  {
    path: 'poser-une-question',
    loadChildren: './pages/mon-compte/poser-une-question/poser-une-question.module#PoserUneQuestionPageModule'
  },

  {
    path: 'questions-similaire',
    loadChildren: './pages/mon-compte/questions-similaire/questions-similaire.module#QuestionsSimilairePageModule'
  },

  // compte rav
  {
    path: 'compte-rav/liste-questions',
    loadChildren: './pages/compte-abichid/liste-questions/liste-questions.module#ListeQuestionsPageModule'
  },
  {
    path: 'compte-rav/question/:id',
    loadChildren: './pages/faq/question/question.module#QuestionPageModule'
  },


  // compte moderateur
  {
    path: 'compte-moderateur/liste-questions',
    loadChildren: './pages/compte-moderateur/liste-questions/liste-questions.module#ListeQuestionsPageModule'
  },
  {
    path: 'compte-moderateur/mots-cles-question/:id',
    loadChildren: './pages/compte-moderateur/liste-mots-cles/liste-mots-cles.module#ListeMotsClesPageModule'
  },
  {
    path: 'compte-moderateur/liste-question-similaire/:id',
    loadChildren: './pages/compte-moderateur/liste-question-similaire/liste-question-similaire.module#ListeQuestionSimilairePageModule'
  },
  {
    path: 'compte-moderateur/question/:id',
    loadChildren: './pages/faq/question/question.module#QuestionPageModule'
  },
  {
    path: 'compte-moderateur/liste-questions-mots-Cles',
    loadChildren: './pages/compte-moderateur/liste-questions-mots-cles/liste-questions-mots-cles.module#ListeQuestionsMotsClesPageModule'
  },
  // compte user, modérateur, rav
  {
    path: 'profil',
    loadChildren: './pages/mon-compte/profil/profil.module#ProfilPageModule'
  },
  // faq
  {

    path: 'liste-questions',
    loadChildren: './pages/faq/liste-questions/liste-questions.module#ListeQuestionsPageModule'
  },
  {
    path: 'question',
    loadChildren: './pages/faq/question/question.module#QuestionPageModule'
  },
  {
    path: 'mon-compte/question/:id',
    loadChildren: './pages/faq/question/question.module#QuestionPageModule'
  },
  {
    path: 'recherche-questions',
    loadChildren: './pages/faq/recherche-question/recherche-question.module#RechercheQuestionPageModule'
  },


  {
    path: 'connexion',
    loadChildren: './pages/auth/connexion/connexion.module#ConnexionPageModule'
  },
  {
    path: 'inscription',
    loadChildren: './pages/auth/inscription/inscription.module#InscriptionPageModule'
  },
  {
    path: 'motDePasseOublier',
    loadChildren: './pages/auth/mot-de-passe-oublier/mot-de-passe-oublier.module#MotDePasseOublierPageModule'
  },

  {
    path: 'modificationMotDePasse',
    loadChildren: './pages/mon-compte/modification-mot-de-passe/modification-mot-de-passe.module#ModificationMotDePassePageModule'
  },



  {
    path: 'rechercheQuestionSimilaire',
    loadChildren: './pages/compte-moderateur/recherche-question-similaire/recherche-question-similaire.module#RechercheQuestionSimilairePageModule'
  },





  {
    path: 'liste-categories-videos',
    loadChildren: './pages/videos/liste-categories/liste-categories.module#ListeCategoriesPageModule'
  },
  { path: 'listeVideos', loadChildren: './pages/videos/liste-videos/liste-videos.module#ListeVideosPageModule' },
  { path: 'faire-un-don', loadChildren: './pages/faire-un-don/faire-un-don.module#FaireUnDonPageModule' },
  { path: 'a-propos', loadChildren: './pages/a-propos/a-propos.module#AProposPageModule' },
  { path: 'selection-categorie-question', loadChildren: './pages/mon-compte/selection-categorie-question/selection-categorie-question.module#SelectionCategorieQuestionPageModule' },
  { path: 'selection-categorie2-question', loadChildren: './pages/mon-compte/selection-categorie2-question/selection-categorie2-question.module#SelectionCategorie2QuestionPageModule' },
  { path: 'selection-categorie3-question', loadChildren: './pages/mon-compte/selection-categorie3-question/selection-categorie3-question.module#SelectionCategorie3QuestionPageModule' },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
