import { Injectable } from '@angular/core';

// rxjs
import { of, Observable, Observer } from 'rxjs';

// modèles
import { JwtTokenModel } from '../../models/auth/jwt-token.model';
import { DataLoginModel } from '../../models/auth/data-login.model';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public jwtToken: JwtTokenModel;

  constructor() {
  }

  public connexion(dataAuth: DataLoginModel): Observable<boolean> {

    return Observable.create(
      (observer: Observer<boolean>) => {

        this.jwtToken = this.getFakeJwtToken(dataAuth);

        if (this.jwtToken) {
          observer.next(true);
        }
        else {
          observer.error(false);
        }
        observer.complete();
      }
    )

  }

  // fake data
  private getFakeJwtToken(dataAuth: DataLoginModel): JwtTokenModel {
    let jwtToken: JwtTokenModel;
    if (dataAuth.login == "user") {
      jwtToken = {
        access_token: "eeeeee",
        expire_in: 600,
        refresh_token: "rrrrr",
        profile: "u",
      };
    }
    else if (dataAuth.login == "moderateur") {
      jwtToken = {
        access_token: "eeeeee",
        expire_in: 600,
        refresh_token: "rrrrr",
        profile: "m",
      };
    }
    else if (dataAuth.login == "rav") {
      jwtToken = {
        access_token: "eeeeee",
        expire_in: 600,
        refresh_token: "rrrrr",
        profile: "r",
      };
    }
    return jwtToken;
  }
}