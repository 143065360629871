// plugin angular
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

// plugin ionic
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

// plugin ionic native
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

// components
import { AppComponent } from './app.component';

// modules
import { AppRoutingModule } from './app-routing.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';

// services
// liste questions
import { ListeQuestionsService } from './services/faq/liste-questions/liste-questions.service';
import { ListeQuestionsFakeService } from './services/faq/liste-questions/liste-question.fake.service';
// menu
import { MenuService } from './services/menu/menu.service';
// auth
import { AuthService } from './services/auth/auth.service';
import { AuthFakeService } from './services/auth/auth.fake.service';

// config
import { environment } from '../environments/environment';
import { ChatService } from './services/chat/chat.service';
import { ChatFakeService } from './services/chat/chat.fake.service';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    ReactiveFormsModule,
  ],
  providers: [
    StatusBar,
    SplashScreen,
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy
    },

    // menu
    MenuService,

    // auth
    {
      provide: AuthService,
      useClass: environment.production ? AuthService : AuthFakeService
    },

    // liste questions
    {
      provide: ListeQuestionsService,
      useClass: environment.production ? ListeQuestionsService : ListeQuestionsService
    },

    // chat
    {
      provide: ChatService,
      useClass: environment.production ? ChatService : ChatFakeService
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
