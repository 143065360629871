import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { CategorieQuestionModel } from '../../../models/faq/categorie-question.model';
import { QuestionModel } from '../../../models/faq/question.model';

@Injectable({
    providedIn: 'root'
})
export class ListeQuestionsService {

    public motCle: string;
    public idMotCle: Array<string> = [];
    public baseUrl = 'http://vps503739.ovh.net/back-app-abichid/web/api/';
    constructor(
        private httpClient: HttpClient
    ) {

    }

    public getListeCategorie(keyword: string = null): Observable<Array<CategorieQuestionModel>> {
        return this.httpClient.get<Array<CategorieQuestionModel>>(this.baseUrl + 'mots-cles-recherche-questions?keyword=' + keyword, {});
    }

    public getListeQuestion(): Observable<any> {
        return of(this.getFakeListeQuestion())
    }

    public getListeQuestionRecherche(keyword: string = null): Observable<Array<QuestionModel>> {
        return this.httpClient.get<Array<QuestionModel>>(this.baseUrl + 'mots-cles-recherche-questions?keyword=' + keyword, {});
    }

    public getQuestion(id: number): Observable<QuestionModel> {
        return of(this.getFakeQuestion())
    }

    // fake data
    private getFakeCategorieQuestion(): Array<CategorieQuestionModel> {

        return [
            {
                id: '1',
                libelle: 'chabath',
                nbQuestions: 8,
                categorieEnfant: [
                    {
                        id: '2',
                        libelle: 'tefila',
                        nbQuestions: 7,
                        isOpen: false,
                        categorieEnfant: [
                            {
                                id: '3',
                                libelle: 'moussaf',
                                nbQuestions: 3,
                                isOpen: false,
                            },
                            {
                                id: '4',
                                libelle: 'chaharit',
                                nbQuestions: 4,
                                isOpen: false,
                            }
                        ],
                    },
                    {
                        id: '5',
                        libelle: 'seouda de chabath',
                        nbQuestions: 1,
                        isOpen: false,
                    }
                ],
                isOpen: false,
            },
            {
                id: '6',
                libelle: 'cacherouth',
                nbQuestions: 3,
                isOpen: false,
            }
        ]
    }

    private getFakeCategorieQuestionKeywordChabath(): Array<CategorieQuestionModel> {
        return [
            {
                id: '1',
                libelle: 'chabath',
                nbQuestions: 8,
                categorieEnfant: [
                    {
                        id: '2',
                        libelle: 'tefila',
                        nbQuestions: 7,
                        isOpen: false,
                        categorieEnfant: [
                            {
                                id: '3',
                                libelle: 'moussaf',
                                nbQuestions: 3,
                                isOpen: false,
                            },
                            {
                                id: '4',
                                libelle: 'chaharit',
                                nbQuestions: 4,
                                isOpen: false,
                            }
                        ],
                    },
                    {
                        id: '5',
                        libelle: 'seouda de chabath',
                        nbQuestions: 1,
                        isOpen: false,
                    }
                ],
                isOpen: true,
            },

        ]
    }

    private getFakeCategorieQuestionKeywordChabathChaharit(): Array<CategorieQuestionModel> {

        return [
            {
                id: '1',
                libelle: 'chabath',
                nbQuestions: 4,
                categorieEnfant: [
                    {
                        id: '2',
                        libelle: 'tefila',
                        nbQuestions: 4,
                        isOpen: true,
                        categorieEnfant: [
                            {
                                id: '4',
                                libelle: 'chaharit',
                                nbQuestions: 4,
                                isOpen: false,
                            }
                        ],
                    },

                ],
                isOpen: true,
            },

        ]
    }

    private getFakeListeQuestion(): Array<QuestionModel> {
        return [
            {
                id: 1,
                title: "Priere de Hol pendant chabath"
            },
            {
                id: 2,
                title: "Birkat cohanim chabath"
            },
        ]
    }

    private getFakeQuestion(): any {

        let question: QuestionModel = {
            id: 1,
            title: "Priere de Hol pendant chabath",
        }

        return question;
    }
}
