import { Injectable } from '@angular/core';

// modèles
import { MenuItemModel } from '../../models/menu/menu.model';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  public lienMenu: Array<MenuItemModel> = [];

  constructor() { }
  getMenu() {
    this.lienMenu = [{
      title: 'Accueil',
      url: '/accueil',
      icon: 'home'
    },
    {
      title: 'Rechercher une question',
      url: '/liste-questions',
      icon: 'search'
    },
    {
      title: 'Faire un don',
      url: '/faire-un-don',
      icon: 'logo-usd'
    },
    {
      title: 'Vidéos du Rav',
      url: '/liste-categories-videos',
      icon: 'logo-youtube'
    },
    {
      title: 'A propos',
      url: '/a-propos',
      icon: 'information-circle-outline'
    }]
  }
  getMenuUser() {
    this.lienMenu = [
      {
        title: 'Accueil',
        url: '/accueil',
        icon: 'home'
      },
      {
        title: 'Poser une question',
        url: '/poser-une-question',
        icon: 'help'
      },
      {
        title: 'Rechercher une question',
        url: '/liste-questions',
        icon: 'search'
      },
      {
        title: 'Historique de mes questions',
        url: '/historique-questions',
        icon: 'list'
      },
      {
        title: 'Faire un don',
        url: '/faire-un-don',
        icon: 'logo-usd'
      },
      {
        title: 'Vidéos du Rav',
        url: '/liste-categories-videos',
        icon: 'logo-youtube'
      },
      {
        title: 'Mon profile',
        url: '/profil',
        icon: 'person'
      },
      {
        title: 'A propos',
        url: '/a-propos',
        icon: 'information-circle-outline'
      },

    ]

    console.log("menu user")
  }

  getMenuRav() {
    this.lienMenu = [
      {
        title: 'Accueil',
        url: '/accueil',
        icon: 'home'
      },
      {
        title: 'Rechercher une question',
        url: '/liste-questions',
        icon: 'search'
      },
      {
        title: 'Questions en attente (5)',
        url: '/compte-rav/liste-questions',
        icon: 'list'
      },
      {
        title: 'Vidéos du Rav',
        url: '/liste-categories-videos',
        icon: 'logo-youtube'
      },
      {
        title: 'Mon profile',
        url: '/profil',
        icon: 'person'
      },
      {
        title: 'A propos',
        url: '/a-propos',
        icon: 'information-circle-outline'
      },

    ]

    console.log("menu user")
  }

  getMenuModerateur() {
    this.lienMenu = [
      {
        title: 'Accueil',
        url: '/accueil',
        icon: 'home'
      },
      {
        title: 'Rechercher une question',
        url: '/liste-questions',
        icon: 'search'
      },
      {
        title: 'Questions en attente (5)',
        url: '/compte-moderateur/liste-questions',
        icon: 'list'
      },
      {
        title: 'Mot clés à valider (5)',
        url: '/compte-moderateur/liste-questions-mots-Cles',
        icon: 'list'
      },
      {
        title: 'Vidéos du Rav',
        url: '/liste-categories-videos',
        icon: 'logo-youtube'
      },
      {
        title: 'Mon profile',
        url: '/profil',
        icon: 'person'
      },
      {
        title: 'A propos',
        url: '/a-propos',
        icon: 'information-circle-outline'
      },

    ]

    console.log("menu user")
  }
}
