import { Injectable } from '@angular/core';

// rxjs
import { of, Observable, Observer } from 'rxjs';

// modèles
import { JwtTokenModel } from '../../models/auth/jwt-token.model';
import { DataLoginModel } from '../../models/auth/data-login.model';
import { MenuService } from '../menu/menu.service';


@Injectable({
  providedIn: 'root'
})
export class AuthFakeService {
  public jwtToken: JwtTokenModel;

  constructor(private menuService: MenuService) {
    console.log('fake auth')
  }

  public connexion(dataAuth: DataLoginModel): Observable<boolean> {

    return Observable.create(
      (observer: Observer<boolean>) => {

        this.jwtToken = this.getFakeJwtToken(dataAuth);

        if (this.jwtToken) {
          if (this.jwtToken.profile == "u") {
            this.menuService.getMenuUser();
          }
          else if (this.jwtToken.profile == "m") {
            this.menuService.getMenuModerateur();
          }
          else if (this.jwtToken.profile == "r") {
            this.menuService.getMenuRav();
          }

          observer.next(true);
        }
        else {
          observer.error(false);
        }
        observer.complete();
      }
    )

  }

  // fake data
  private getFakeJwtToken(dataAuth: DataLoginModel): JwtTokenModel {
    let jwtToken: JwtTokenModel;
  console.log(dataAuth.login)
    if (dataAuth.login == "user") {
      jwtToken = {
        access_token: "eeeeee",
        expire_in: 600,
        refresh_token: "rrrrr",
        profile: "u",
      };
    }
    else if (dataAuth.login == "moderateur") {
      jwtToken = {
        access_token: "eeeeee",
        expire_in: 600,
        refresh_token: "rrrrr",
        profile: "m",
      };
    }
    else if (dataAuth.login == "rav") {
      jwtToken = {
        access_token: "eeeeee",
        expire_in: 600,
        refresh_token: "rrrrr",
        profile: "r",
      };
    }
    return jwtToken;
  }
}